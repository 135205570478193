import type {FC} from 'react';
import React, {Component, useEffect, useState} from 'react';
import type {NextPage} from 'next';
import type {AppProps} from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import {Toaster} from 'react-hot-toast';
import {Provider as ReduxProvider} from 'react-redux';
import nProgress from 'nprogress';
import {CacheProvider} from '@emotion/react';
import type {EmotionCache} from '@emotion/cache';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {RTL} from '../components/rtl';
import {SettingsButton} from '../components/settings-button';
import {SplashScreen} from '../components/splash-screen';
import {SessionProvider, signOut, useSession} from "next-auth/react"
import {SettingsConsumer, SettingsProvider} from '../contexts/settings-context';
import {store} from '../store';
import {createTheme} from '../theme';
import {createEmotionCache} from '../utils/create-emotion-cache';
import '../i18n';
import {SettingsDrawer} from "../components/settings-drawer";
import {ModalProvider} from "../contexts/modal-context";
import axiosApiInstance from "../utils/axios";

type EnhancedAppProps = AppProps & {
  Component: NextPage;
  emotionCache: EmotionCache;
}

Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

const clientSideEmotionCache = createEmotionCache();

const App: FC<EnhancedAppProps> = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps: { session, ...pageProps } } = props;

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>
          Mystes
        </title>
        <meta
          name="viewport"
          content="initial-scale=1, width=device-width"
        />
      </Head>
      <ReduxProvider store={store}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <SessionProvider
              session={session}
              refetchInterval={5}
              refetchOnWindowFocus={true}
          >
            <SettingsProvider>
              <SettingsConsumer>
                {(settings) => {
                  // Prevent theme flicker when restoring custom settings from browser storage
                  if (!settings.isInitialized) {
                    // return null;
                  }

                  const theme = createTheme({
                    colorPreset: settings.colorPreset,
                    contrast: settings.contrast,
                    direction: settings.direction,
                    paletteMode: settings.paletteMode,
                    responsiveFontSizes: settings.responsiveFontSizes
                  });

                  return (
                    <ThemeProvider theme={theme}>
                      <ModalProvider>
                        <>
                          <Head>
                            <title>Mystes</title>
                            <meta
                              name="color-scheme"
                              content={settings.paletteMode}
                            />
                            <meta
                              name="theme-color"
                              content={theme.palette.neutral[900]}
                            />
                          </Head>
                          <RTL direction={settings.direction}>
                            <CssBaseline />
                              <>
                              {Component.auth ? (
                                <Auth>
                                  {getLayout(<Component {...pageProps} />)}
                                  <SettingsButton onClick={settings.handleDrawerOpen} />
                                  <SettingsDrawer
                                    canReset={settings.isCustom}
                                    onClose={settings.handleDrawerClose}
                                    onReset={settings.handleReset}
                                    onUpdate={settings.handleUpdate}
                                    open={settings.openDrawer}
                                    values={{
                                      colorPreset: settings.colorPreset,
                                      contrast: settings.contrast,
                                      direction: settings.direction,
                                      paletteMode: settings.paletteMode,
                                      responsiveFontSizes: settings.responsiveFontSizes,
                                      stretch: settings.stretch,
                                      layout: settings.layout,
                                      navColor: settings.navColor
                                    }}
                                  />
                                </Auth>) : getLayout(<Component {...pageProps} />)}
                                </>
                            <Toaster />
                          </RTL>
                        </>
                      </ModalProvider>
                    </ThemeProvider>
                  );
                }}
              </SettingsConsumer>
            </SettingsProvider>
          </SessionProvider>
        </LocalizationProvider>
      </ReduxProvider>
    </CacheProvider>
  );
};

interface AuthProps {
  children: React.ReactNode;
}

const Auth: FC<AuthProps> = ({children})  => {
  const { data, status } = useSession({ required: true })
  const [showSplash, setShowSplash] = useState(true)

  useEffect(() => {
    if (status === "loading") {
      return
    }
    if (!data.accessToken || (data?.error === "should_reauthenticate")) {
      setShowSplash(true)
      signOut({redirect: false})
    }
    else {
      axiosApiInstance.defaults.headers.common['Authorization'] = "Bearer " + data.accessToken;
      setShowSplash(false)
    }
  }, [data, status])

  if (showSplash) {
    return <SplashScreen />
  }

  return (
    <>
      {children}
    </>
  )
}

export default App;
