import type {FC, ReactElement} from 'react';
import PropTypes from 'prop-types';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import type {Direction} from '@mui/material';
import {Chip, Stack, SvgIcon, Typography} from '@mui/material';

interface Option {
  label: string;
  value: Direction;
  icon: ReactElement;
}

const options: Option[] = [
  {
    label: 'Gauche à droite',
    value: 'ltr',
    icon: (
      <SvgIcon fontSize="small">
        <FormatAlignLeftIcon />
      </SvgIcon>
    )
  },
  {
    label: 'Droite à gauche',
    value: 'rtl',
    icon: (
      <SvgIcon fontSize="small">
        <FormatAlignRightIcon />
      </SvgIcon>
    )
  }
];

interface OptionsDirectionProps {
  onChange?: (value: Direction) => void;
  value?: Direction;
}

export const OptionsDirection: FC<OptionsDirectionProps> = (props) => {
  const { onChange, value } = props;

  return (
    <Stack spacing={1}>
      <Typography
        color="text.secondary"
        variant="overline"
      >
        Orientation
      </Typography>
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        gap={2}
      >
        {options.map((option) => (
          <Chip
            icon={option.icon}
            key={option.label}
            label={option.label}
            onClick={() => onChange?.(option.value)}
            sx={{
              borderColor: 'transparent',
              borderRadius: 1.5,
              borderStyle: 'solid',
              borderWidth: 2,
              ...(option.value === value && {
                borderColor: 'primary.main'
              })
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};

OptionsDirection.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOf(['ltr', 'rtl'])
};
