import type {TypedUseSelectorHook} from 'react-redux';
import {useDispatch as useReduxDispatch, useSelector as useReduxSelector} from 'react-redux';
import type {ThunkAction} from 'redux-thunk';
import type {Action} from '@reduxjs/toolkit';
import {configureStore} from '@reduxjs/toolkit';
import {rootReducer} from './root-reducer';
import {mystesApi, nodeApi} from "../api";
import {setupListeners} from "@reduxjs/toolkit/query";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(mystesApi.middleware, nodeApi.middleware),
  devTools: true,
});

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();
