import type {FC} from 'react';
import {Box, CircularProgress} from '@mui/material';
import {Logo} from "./logo";

export const SplashScreen: FC = () => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: 'neutral.900',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100vw',
    }}
  >
    <Box  sx={{
      height: 128,
      width: 128,
    }}>
      <Logo/>
    </Box>
    <Box sx={{ pt: 4 }}>
      <CircularProgress  />
    </Box>
  </Box>
);
