import React, {FC, ReactElement, ReactNode, useCallback, useMemo, useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog, {DialogProps} from "@mui/material/Dialog";

const initialDialogConfig = {
  actions: undefined,
  content: undefined,
  dialogProps: undefined,
  title: "Title",
  onConfirm: undefined,
  cancelText: "Annuler",
  confirmText: "Confirmer"
}

export interface ModalConfig {
  dialogProps?: DialogProps;
  title?: string;
  content?: ReactNode | ReactNode[];
  actions?: ReactNode | ReactNode[];
  onConfirm?: () => void;
  cancelText?: string;
  confirmText?: string;
}

interface ModalContextType {
  show: (config: ModalConfig) => Promise<string>
  hide: () => void;
}

interface ModalProviderProps {
  children?: ReactElement;
}

export const ModalContext = React.createContext<ModalContextType>({
  show: (_) => new Promise<string>(() => {}),
  hide: () => {},
});


export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const [modalConfig, setModalConfig] = useState<ModalConfig>(initialDialogConfig)
  const [resolveReject, setResolveReject] = useState<any[]>([]);
  const [resolve, reject] = resolveReject;

  const show = useCallback((config: ModalConfig): Promise<any> => {
    return new Promise<string>((resolve, reject) => {
      setModalConfig(config);
      setResolveReject([resolve, reject]);
    });
  }, [])

  const hide = useCallback(() => {
    setResolveReject([]);
  }, [])

  const handleCancel = useCallback(() => {
    if (reject) {
      reject("canceled");
      hide();
    }
  }, [reject, hide]);

  const handleConfirm = useCallback(() => {
    if (resolve) {
      resolve("confirmed");
      hide();
    }
  }, [resolve, hide]);

  const defaultActions = useMemo(() => (
      <>
        <Button autoFocus onClick={handleCancel}>{modalConfig.cancelText ?? initialDialogConfig.cancelText}</Button>
        <Button onClick={handleConfirm} >{modalConfig.confirmText ?? initialDialogConfig.confirmText}</Button>
      </>
    ), [handleCancel, handleConfirm, modalConfig.cancelText, modalConfig.confirmText]
  )

  return (
    <ModalContext.Provider value={{
      show,
      hide,
    }}>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={resolveReject.length === 2}
        {...modalConfig.dialogProps}
      >
        <DialogTitle>{modalConfig.title}</DialogTitle>
        <DialogContent dividers>
          {modalConfig.content}
        </DialogContent>
        <DialogActions>
          {modalConfig.actions || defaultActions}
        </DialogActions>
      </Dialog>
      {children}
    </ModalContext.Provider>
  );
};
