import axios from "axios";

const axiosApiInstance = axios.create();

// axiosApiInstance.interceptors.request.use(async config => {
//   const session = await getSession()
//   if (config.headers && config.headers.common) {
//     config.headers.common.set('Authorization', "Bearer " + session?.accessToken)
//   }
//   else {
//     config.headers = {
//       "Authorization": "Bearer " + session?.accessToken
//     }
//   }
//   return config
// })

// axiosApiInstance.interceptors.response.use(
//   response => response,
//   async error => {
//     const { response } = error;
//     const originalRequest = error.config;
//
//     if (!response || response.status !== 401 || originalRequest._retry)
//       return Promise.reject(error);
//
//     originalRequest._retry = true;
//
//     return axiosApiInstance(originalRequest);
//   }
// );

export default axiosApiInstance;