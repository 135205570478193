import {FC} from "react";

export const Logo: FC = () => {
    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           width="100%" viewBox="0 0 300 300" enableBackground="new 0 0 300 300" xmlSpace="preserve">
<path fill="#E4032D" opacity="1.000000" stroke="none"
      d="
M301.000000,2.000000
	C301.000000,2.000000 301.000000,2.500000 300.749084,3.080092
	C300.332428,5.299640 300.022125,6.939061 300.021729,8.578558
	C299.998566,103.469666 299.995209,198.360779 300.042725,293.251862
	C300.043671,295.168091 300.666992,297.083954 301.000000,299.000000
	C301.000000,299.000000 301.000000,299.500000 300.531342,299.749451
	C200.375122,299.999268 100.687561,299.999634 1.000000,300.000000
	C1.000000,300.000000 1.000000,299.500000 1.250945,298.919922
	C1.667696,296.700409 1.978206,295.060974 1.978607,293.421509
	C2.001815,198.530365 2.005189,103.639221 1.957661,8.748102
	C1.956701,6.831909 1.333111,4.916027 1.000000,3.000000
	C1.000000,3.000000 1.000000,2.500000 1.468656,2.251090
	C101.624878,2.001453 201.312439,2.000726 301.000000,2.000000
M166.934753,170.526642
	C166.705429,170.824707 166.476120,171.122772 166.243088,171.305573
	C166.243088,171.305573 166.353546,171.338760 165.703125,171.308289
	C165.321503,171.740921 164.939880,172.173538 164.489365,172.671265
	C164.489365,172.671265 164.394608,172.673325 163.807632,172.438354
	C163.204437,172.863358 162.601227,173.288345 161.233902,174.088455
	C147.714081,177.447815 137.481918,173.226913 133.602005,162.153519
	C130.195480,152.431168 130.202148,142.490158 133.910492,132.788895
	C137.233505,124.095665 143.946304,119.799072 153.289490,119.944153
	C162.277863,120.083717 166.264343,123.183830 169.877838,132.928894
	C178.587692,132.928894 187.339340,132.928894 196.330063,132.928894
	C195.212906,119.448532 189.195969,109.254173 177.104919,103.846703
	C158.768097,95.645943 140.294205,95.948029 123.200676,107.091637
	C106.697060,117.850677 101.750832,134.344025 103.164246,153.188782
	C104.809700,175.127289 118.058487,190.416229 139.288605,194.588562
	C144.471695,195.607224 149.896973,195.983139 155.181259,195.799820
	C185.389664,194.751892 202.562027,175.377380 198.136612,142.471436
	C183.594376,142.471436 169.048859,142.471436 154.074936,142.471436
	C154.074936,148.821686 154.074936,154.797653 154.288452,161.551117
	C155.908661,162.034943 157.508621,162.830475 159.153320,162.937302
	C162.510147,163.155334 165.891068,163.002594 169.216614,163.002594
	C169.216614,165.342834 169.216614,166.803696 169.186615,168.142090
	C169.186615,168.142090 169.312454,168.134201 168.825989,168.437836
	C168.664230,168.776810 168.502472,169.115799 168.319016,169.299561
	C168.319016,169.299561 168.474457,169.319565 167.935822,169.522049
	C167.752045,169.846542 167.568283,170.171021 167.355621,170.359467
	C167.355621,170.359467 167.493347,170.378708 166.934753,170.526642
M212.316299,194.976120
	C213.541245,195.317017 214.765274,195.951096 215.991287,195.954971
	C235.614700,196.017166 255.238571,196.036148 274.861511,195.920334
	C276.260498,195.912064 277.652863,194.780899 279.076111,193.213501
	C279.076111,186.656799 279.076111,180.100098 279.076111,173.159424
	C265.231689,173.159424 252.178864,173.159424 239.139328,173.159424
	C239.139328,167.396072 239.139328,162.048904 239.139328,156.284424
	C249.241943,156.284424 258.968475,156.284424 268.984711,156.284424
	C268.984711,149.587830 268.984711,143.311874 268.722351,136.287872
	C267.047241,135.858276 265.379852,135.097488 263.695618,135.058090
	C257.375183,134.910187 251.036346,134.749893 244.729416,135.076645
	C239.813141,135.331345 239.924667,132.453033 239.965439,129.046448
	C240.004944,125.744896 239.620804,122.758034 244.634125,122.919525
	C253.777618,123.214073 262.939819,123.107719 272.088226,122.901817
	C273.718964,122.865112 275.320709,121.538261 276.952545,119.894447
	C276.955231,113.305618 276.957916,106.716782 276.717407,99.365753
	C275.398438,98.911194 274.081207,98.066765 272.760193,98.060745
	C253.856567,97.974770 234.952164,97.962044 216.048950,98.085358
	C214.688217,98.094238 213.335251,99.294594 211.959579,100.005669
	C211.959579,100.005669 211.899033,99.975899 211.194458,100.313034
	C210.796219,101.837250 210.054428,103.360611 210.050659,104.885796
	C209.981155,133.019806 209.972076,161.154114 210.072235,189.287933
	C210.077942,190.890350 211.129868,192.489029 211.923203,194.098099
	C211.923203,194.098099 211.904663,194.325821 212.316299,194.976120
M28.940687,100.002045
	C28.940687,100.002045 28.864487,99.967972 28.171152,100.321175
	C27.780964,101.681763 27.053453,103.041557 27.050297,104.403046
	C26.984346,132.849976 26.976587,161.297165 27.069130,189.743942
	C27.073862,191.197876 28.078884,192.648560 28.898493,194.094009
	C28.898493,194.094009 28.899023,194.374390 29.322638,195.012726
	C30.222002,195.337601 31.120584,195.943527 32.020847,195.946030
	C52.149895,196.002075 72.279312,196.024490 92.407875,195.912521
	C93.619736,195.905777 94.825073,194.727081 96.022766,193.145096
	C95.988144,186.549835 95.953522,179.954590 95.370461,172.725586
	C93.590309,172.483353 91.811462,172.042831 90.029793,172.031113
	C78.699707,171.956680 67.369003,171.976883 55.853699,171.057434
	C55.914082,166.718216 55.974464,162.378983 56.967457,158.025101
	C65.077850,158.016510 73.190186,158.097870 81.296967,157.922455
	C82.892708,157.887924 84.467018,156.862778 86.116615,155.322922
	C86.073753,149.221619 86.030884,143.120300 85.718079,136.275497
	C84.040314,135.850052 82.370712,135.100082 80.683311,135.057648
	C74.694138,134.907059 68.685265,134.748230 62.710205,135.074768
	C57.775574,135.344452 57.932865,132.420197 57.958614,129.029541
	C57.983852,125.705711 57.654118,122.749100 62.649181,122.919052
	C71.461884,123.218918 80.294876,123.112495 89.112694,122.899506
	C90.732658,122.860374 92.320930,121.509674 93.895760,119.857315
	C93.910126,113.302086 93.924484,106.746857 93.716362,99.418022
	C92.408813,98.946167 91.103180,98.069504 89.793434,98.063324
	C70.882309,97.974190 51.970379,97.962311 33.059628,98.084625
	C31.693514,98.093460 30.335041,99.283981 28.940687,100.002045
M179.595398,134.070541
	C176.929108,134.070541 174.262817,134.070541 171.596527,134.070541
	C171.606659,134.282623 171.616806,134.494705 171.626938,134.706787
	C179.209732,134.706787 186.792511,134.706787 194.375305,134.706787
	C194.375992,134.494797 194.376678,134.282822 194.377365,134.070831
	C189.772354,134.070831 185.167343,134.070831 179.595398,134.070541
z"/>
          <path fill="#E50F38" opacity="1.000000" stroke="none"
                d="
M301.000000,1.500000
	C201.312439,2.000726 101.624878,2.001453 1.468656,2.001090
	C2.500000,1.666667 3.999851,1.042363 5.500021,1.041595
	C96.166664,0.995186 186.833328,1.000000 277.500000,1.000000
	C285.333344,1.000000 293.166656,1.000000 301.000000,1.500000
z"/>
          <path fill="#E92D50" opacity="1.000000" stroke="none"
                d="
M1.000000,300.500000
	C100.687561,299.999634 200.375122,299.999268 300.531342,299.999451
	C299.500000,300.333313 298.000153,300.957611 296.499969,300.958374
	C205.666672,301.004791 114.833336,301.000000 24.000000,301.000000
	C16.333334,301.000000 8.666667,301.000000 1.000000,300.500000
z"/>
          <path fill="#E50F38" opacity="1.000000" stroke="none"
                d="
M1.000000,3.468657
	C1.333111,4.916027 1.956701,6.831909 1.957661,8.748102
	C2.005189,103.639221 2.001815,198.530365 1.978607,293.421509
	C1.978206,295.060974 1.667696,296.700409 1.250945,298.669922
	C1.000000,200.645767 1.000000,102.291542 1.000000,3.468657
z"/>
          <path fill="#E50F38" opacity="1.000000" stroke="none"
                d="
M301.000000,298.531342
	C300.666992,297.083954 300.043671,295.168091 300.042725,293.251862
	C299.995209,198.360779 299.998566,103.469666 300.021729,8.578558
	C300.022125,6.939061 300.332428,5.299640 300.749084,3.330092
	C301.000000,101.354225 301.000000,199.708450 301.000000,298.531342
z"/>
          <path fill="#FFFCFC" opacity="1.000000" stroke="none"
                d="
M154.074936,160.773621
	C154.074936,154.797653 154.074936,148.821686 154.074936,142.471436
	C169.048859,142.471436 183.594376,142.471436 198.136612,142.471436
	C202.562027,175.377380 185.389664,194.751892 155.181259,195.799820
	C149.896973,195.983139 144.471695,195.607224 139.288605,194.588562
	C118.058487,190.416229 104.809700,175.127289 103.164246,153.188782
	C101.750832,134.344025 106.697060,117.850677 123.200676,107.091637
	C140.294205,95.948029 158.768097,95.645943 177.104919,103.846703
	C189.195969,109.254173 195.212906,119.448532 196.330063,132.928894
	C187.339340,132.928894 178.587692,132.928894 169.877838,132.928894
	C166.264343,123.183830 162.277863,120.083717 153.289490,119.944153
	C143.946304,119.799072 137.233505,124.095665 133.910492,132.788895
	C130.202148,142.490158 130.195480,152.431168 133.602005,162.153519
	C137.481918,173.226913 147.714081,177.447815 161.883423,173.893402
	C163.153488,173.356674 163.774048,173.014999 164.394608,172.673309
	C164.394608,172.673325 164.489365,172.671265 164.790649,172.541687
	C165.512482,172.054306 165.933014,171.696533 166.353546,171.338760
	C166.353546,171.338760 166.243088,171.305573 166.509689,171.264526
	C167.015305,170.941879 167.254333,170.660294 167.493347,170.378708
	C167.493347,170.378708 167.355621,170.359467 167.612793,170.293091
	C168.071457,169.924332 168.272964,169.621948 168.474457,169.319565
	C168.474457,169.319565 168.319016,169.299561 168.577759,169.229767
	C168.995148,168.818054 169.153809,168.476135 169.312454,168.134201
	C169.312454,168.134201 169.186615,168.142090 169.490051,167.990601
	C170.523178,165.771820 171.252899,163.704498 172.287430,160.773621
	C165.584213,160.773621 159.829575,160.773621 154.074936,160.773621
z"/>
          <path fill="#FFFDFD" opacity="1.000000" stroke="none"
                d="
M85.988014,137.018997
	C86.030884,143.120300 86.073753,149.221619 85.617157,155.879639
	C76.705528,156.442245 68.292206,156.381241 59.882378,156.524734
	C58.591629,156.546753 57.316959,157.511200 56.034851,158.039764
	C55.974464,162.378983 55.914082,166.718216 56.037743,171.957397
	C57.855701,172.987427 59.488998,173.221268 61.123627,173.230927
	C72.721901,173.299438 84.320442,173.322083 95.918900,173.359344
	C95.953522,179.954590 95.988144,186.549835 95.568161,193.730515
	C73.042046,194.335419 50.970535,194.354904 28.899023,194.374390
	C28.899023,194.374390 28.898493,194.094009 28.750813,193.622147
	C28.612099,183.015839 28.602364,172.881348 28.633934,162.746979
	C28.699127,141.820602 28.786335,120.894302 28.864487,99.967972
	C28.864487,99.967972 28.940687,100.002045 29.427071,99.965515
	C51.255253,100.016525 72.597054,100.104080 93.938850,100.191628
	C93.924484,106.746857 93.910126,113.302086 93.453011,120.398880
	C81.667419,121.024117 70.324577,121.107780 58.636818,121.101013
	C57.550495,121.334694 56.809097,121.658798 55.966553,122.439262
	C55.934116,127.268440 56.002823,131.641266 56.145260,136.358475
	C57.137459,136.730835 58.055923,136.758820 59.438942,136.864441
	C68.598335,136.967697 77.293175,136.993347 85.988014,137.018997
z"/>
          <path fill="#FFFDFD" opacity="1.000000" stroke="none"
                d="
M268.984711,137.035904
	C268.984711,143.311874 268.984711,149.587830 268.984711,156.284424
	C258.968475,156.284424 249.241943,156.284424 239.139328,156.284424
	C239.139328,162.048904 239.139328,167.396072 239.139328,173.159424
	C252.178864,173.159424 265.231689,173.159424 279.076111,173.159424
	C279.076111,180.100098 279.076111,186.656799 278.596985,193.778793
	C256.046783,194.337997 233.975723,194.331909 211.904663,194.325821
	C211.904663,194.325821 211.923203,194.098099 211.803070,193.619293
	C211.689743,189.818619 211.695679,186.496735 211.703461,183.174850
	C211.768463,155.441864 211.833786,127.708885 211.899033,99.975899
	C211.899033,99.975899 211.959579,100.005669 212.438980,99.968292
	C234.265793,99.996597 255.613190,100.062271 276.960602,100.127945
	C276.957916,106.716782 276.955231,113.305618 276.483398,120.416611
	C263.653717,120.938774 251.293182,120.938774 238.090012,120.938774
	C238.800186,126.780807 239.410065,131.797623 240.260986,136.877014
	C240.502045,136.939621 240.979492,136.797607 241.443542,136.876434
	C250.933304,136.982132 259.959015,137.009018 268.984711,137.035904
z"/>
          <path fill="#E00011" opacity="1.000000" stroke="none"
                d="
M28.517818,100.144577
	C28.786335,120.894302 28.699127,141.820602 28.633934,162.746979
	C28.602364,172.881348 28.612099,183.015839 28.610657,193.625519
	C28.078884,192.648560 27.073862,191.197876 27.069130,189.743942
	C26.976587,161.297165 26.984346,132.849976 27.050297,104.403046
	C27.053453,103.041557 27.780964,101.681763 28.517818,100.144577
z"/>
          <path fill="#E00013" opacity="1.000000" stroke="none"
                d="
M211.546753,100.144470
	C211.833786,127.708885 211.768463,155.441864 211.703461,183.174850
	C211.695679,186.496735 211.689743,189.818619 211.688919,193.614975
	C211.129868,192.489029 210.077942,190.890350 210.072235,189.287933
	C209.972076,161.154114 209.981155,133.019806 210.050659,104.885796
	C210.054428,103.360611 210.796219,101.837250 211.546753,100.144470
z"/>
          <path fill="#E20020" opacity="1.000000" stroke="none"
                d="
M58.981739,121.191452
	C70.324577,121.107780 81.667419,121.024117 93.467041,120.852409
	C92.320930,121.509674 90.732658,122.860374 89.112694,122.899506
	C80.294876,123.112495 71.461884,123.218918 62.649181,122.919052
	C57.654118,122.749100 57.983852,125.705711 57.958614,129.029541
	C57.932865,132.420197 57.775574,135.344452 62.710205,135.074768
	C68.685265,134.748230 74.694138,134.907059 80.683311,135.057648
	C82.370712,135.100082 84.040314,135.850052 85.853043,136.647247
	C77.293175,136.993347 68.598335,136.967697 59.193501,136.677826
	C57.679516,136.280426 56.875523,136.147263 56.071529,136.014099
	C56.002823,131.641266 55.934116,127.268440 56.279324,122.438477
	C57.456070,121.718040 58.218906,121.454742 58.981739,121.191452
z"/>
          <path fill="#E30725" opacity="1.000000" stroke="none"
                d="
M240.019928,136.814423
	C239.410065,131.797623 238.800186,126.780807 238.090012,120.938774
	C251.293182,120.938774 263.653717,120.938774 276.475037,120.872955
	C275.320709,121.538261 273.718964,122.865112 272.088226,122.901817
	C262.939819,123.107719 253.777618,123.214073 244.634125,122.919525
	C239.620804,122.758034 240.004944,125.744896 239.965439,129.046448
	C239.924667,132.453033 239.813141,135.331345 244.729416,135.076645
	C251.036346,134.749893 257.375183,134.910187 263.695618,135.058090
	C265.379852,135.097488 267.047241,135.858276 268.853516,136.661896
	C259.959015,137.009018 250.933304,136.982132 241.338409,136.774704
	C240.470810,136.466171 240.221054,136.539597 240.019928,136.814423
z"/>
          <path fill="#E10015" opacity="1.000000" stroke="none"
                d="
M212.110474,194.650970
	C233.975723,194.331909 256.046783,194.337997 278.583069,194.257965
	C277.652863,194.780899 276.260498,195.912064 274.861511,195.920334
	C255.238571,196.036148 235.614700,196.017166 215.991287,195.954971
	C214.765274,195.951096 213.541245,195.317017 212.110474,194.650970
z"/>
          <path fill="#E10015" opacity="1.000000" stroke="none"
                d="
M29.110830,194.693558
	C50.970535,194.354904 73.042046,194.335419 95.573502,194.204819
	C94.825073,194.727081 93.619736,195.905777 92.407875,195.912521
	C72.279312,196.024490 52.149895,196.002075 32.020847,195.946030
	C31.120584,195.943527 30.222002,195.337601 29.110830,194.693558
z"/>
          <path fill="#E2001D" opacity="1.000000" stroke="none"
                d="
M276.838989,99.746849
	C255.613190,100.062271 234.265793,99.996597 212.448532,99.935944
	C213.335251,99.294594 214.688217,98.094238 216.048950,98.085358
	C234.952164,97.962044 253.856567,97.974770 272.760193,98.060745
	C274.081207,98.066765 275.398438,98.911194 276.838989,99.746849
z"/>
          <path fill="#E2001D" opacity="1.000000" stroke="none"
                d="
M93.827606,99.804825
	C72.597054,100.104080 51.255253,100.016525 29.443233,99.927032
	C30.335041,99.283981 31.693514,98.093460 33.059628,98.084625
	C51.970379,97.962311 70.882309,97.974190 89.793434,98.063324
	C91.103180,98.069504 92.408813,98.946167 93.827606,99.804825
z"/>
          <path fill="#E0000F" opacity="1.000000" stroke="none"
                d="
M56.501152,158.032440
	C57.316959,157.511200 58.591629,156.546753 59.882378,156.524734
	C68.292206,156.381241 76.705528,156.442245 85.584457,156.365417
	C84.467018,156.862778 82.892708,157.887924 81.296967,157.922455
	C73.190186,158.097870 65.077850,158.016510 56.501152,158.032440
z"/>
          <path fill="#E2061B" opacity="1.000000" stroke="none"
                d="
M154.181702,161.162369
	C159.829575,160.773621 165.584213,160.773621 172.287430,160.773621
	C171.252899,163.704498 170.523178,165.771820 169.505035,168.051849
	C169.216614,166.803696 169.216614,165.342834 169.216614,163.002594
	C165.891068,163.002594 162.510147,163.155334 159.153320,162.937302
	C157.508621,162.830475 155.908661,162.034943 154.181702,161.162369
z"/>
          <path fill="#E10014" opacity="1.000000" stroke="none"
                d="
M95.644684,173.042465
	C84.320442,173.322083 72.721901,173.299438 61.123627,173.230927
	C59.488998,173.221268 57.855701,172.987427 56.130142,172.411621
	C67.369003,171.976883 78.699707,171.956680 90.029793,172.031113
	C91.811462,172.042831 93.590309,172.483353 95.644684,173.042465
z"/>
          <path fill="#E00012" opacity="1.000000" stroke="none"
                d="
M180.078857,134.070679
	C185.167343,134.070831 189.772354,134.070831 194.377365,134.070831
	C194.376678,134.282822 194.375992,134.494797 194.375305,134.706787
	C186.792511,134.706787 179.209732,134.706787 171.626938,134.706787
	C171.616806,134.494705 171.606659,134.282623 171.596527,134.070541
	C174.262817,134.070541 176.929108,134.070541 180.078857,134.070679
z"/>
          <path fill="#E2061B" opacity="1.000000" stroke="none"
                d="
M164.101120,172.555832
	C163.774048,173.014999 163.153488,173.356674 162.265472,173.705841
	C162.601227,173.288345 163.204437,172.863358 164.101120,172.555832
z"/>
          <path fill="#E2061B" opacity="1.000000" stroke="none"
                d="
M166.028336,171.323517
	C165.933014,171.696533 165.512482,172.054306 164.825104,172.509125
	C164.939880,172.173538 165.321503,171.740921 166.028336,171.323517
z"/>
          <path fill="#E2061B" opacity="1.000000" stroke="none"
                d="
M169.069214,168.286011
	C169.153809,168.476135 168.995148,168.818054 168.588608,169.307373
	C168.502472,169.115799 168.664230,168.776810 169.069214,168.286011
z"/>
          <path fill="#E2061B" opacity="1.000000" stroke="none"
                d="
M168.205139,169.420807
	C168.272964,169.621948 168.071457,169.924332 167.627228,170.361115
	C167.568283,170.171021 167.752045,169.846542 168.205139,169.420807
z"/>
          <path fill="#E2061B" opacity="1.000000" stroke="none"
                d="
M167.214050,170.452667
	C167.254333,170.660294 167.015305,170.941879 166.511551,171.322144
	C166.476120,171.122772 166.705429,170.824707 167.214050,170.452667
z"/>
          <path fill="#E4032D" opacity="1.000000" stroke="none"
                d="
M56.145260,136.358475
	C56.875523,136.147263 57.679516,136.280426 58.728951,136.600204
	C58.055923,136.758820 57.137459,136.730835 56.145260,136.358475
z"/>
          <path fill="#E4032D" opacity="1.000000" stroke="none"
                d="
M58.636818,121.101021
	C58.218906,121.454742 57.456070,121.718040 56.380470,121.982117
	C56.809097,121.658798 57.550495,121.334694 58.636818,121.101021
z"/>
          <path fill="#E4032D" opacity="1.000000" stroke="none"
                d="
M240.260986,136.877014
	C240.221054,136.539597 240.470810,136.466171 240.874344,136.695877
	C240.979492,136.797607 240.502045,136.939621 240.260986,136.877014
z"/>
</svg>
    )
}
